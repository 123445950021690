<template>
  <div class="practiceChartPage">
    <!-- <button @click="testBtn">testbtn</button> -->
    <canvas id="line1" ref="line1"></canvas>
    <div class="line_dashed_1"></div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    type: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      currenData: new Array(),
      canvasline: null,
      linectx: null,
      width: 0,
      height: 0,
      arr: [],
      arr1: [],
      arr2: [],
      arr3: [],
      arr4: [],
      isstop: true,
      loopTimeout: 0,
      max_times: 135,
      points_one_times: 4,
      gride_width: 25,
      gx: 4 * ((25 * 5) / 128),
      x_start: 0, // x 开始位置
      y_starts: 50, // 根据传入线的数量. 判断 Y
      draw_lines_index: [0, 1], // 画线数量
      last_points: [
        [0, 0],
        [0, 0],
      ], // 点的 xy,
      current_times: 0,
      current_time_millis: 0,

      // aHeartRateData:[],
      data: [0],
    };
  },
  created() {
    //获取心电图数据
    this.getWave().then((res) => {
      // console.log(this.getWaveChart1);
    });
  },
  mounted() {
    this.canvasline = this.$refs.line1;
    this.linectx = this.canvasline.getContext("2d");
    this.$nextTick(() => {
      let _width = this.canvasline.offsetWidth;
      let _height = this.canvasline.offsetHeight;
      this.canvasline.width = _width;
      this.canvasline.height = _height;
      this.width = this.canvasline.width;
      this.height = this.canvasline.height;
      // 初始化参数, 宽度, 和线条颜色
      this.initparm();
      // 转换数据
      this.Convert16Scale();
      this.loop();
    });
  },
  unmounted() {
    clearTimeout(this.loopTimeout);
    this.isstop = false;
  },
  methods: {
    ...mapActions({
      setWave: "exma/setWave",
    }),
    async getWave() {
      if (this.waveList.length != 0) return;
      try {
        let res = await this.$http.get(this.$interface.waveList);
        this.setWave(res.data.data || []);
      } catch (err) {}
    },
    testBtn() {
      this.loop();
    },
    draw(Q, B, P, N, G, H, A, E) {
      //设置清除重新绘制位置
      this.current_times = this.current_times % G;
      // console.log(this.current_times,G,'G,current_times')
      if (this.IsEmpty()) {
        return;
      }
      if (this.GetSize() < H) {
        return;
      }
      this.clearcanvans(this.current_times, H, N, A);
      let F = new Array();
      for (let J = 0; J < H; J++) {
        F.push(this.DeQueue());
      }
      A.save();
      A.beginPath();
      for (let J = 0; J < E.length; J++) {
        // console.log(J,'J')
        for (let K = 0; K < F.length; K++) {
          // console.log(K,'k')
          let O = F[K];
          let C = O[E[J]] + 23;

          if (this.last_points[J][1] == 0 || !this.last_points[J][1]) {
            if (J == 0) this.last_points[J][1] = 27 + 23;
            if (J == 1) this.last_points[J][1] = 80 + 27 + 23;
          }

          let I = K * parseFloat((this.gride_width * 5) / N);
          let L = parseFloat(
            this.x_start + this.current_times * H * ((this.gride_width * 5) / N)
          );

          if (K == 0) {
            if (this.current_times != 0) {
              A.moveTo(this.last_points[J][0], this.last_points[J][1]);
              let D = C;
              A.lineTo(this.last_points[J][0], D);
              this.last_points[J][0] = this.last_points[J][0];
              this.last_points[J][1] = D;
            } else {
              let D = C;
              A.moveTo(this.x_start, D);
              this.last_points[J][0] = this.x_start;
              this.last_points[J][1] = D;
            }
          } else {
            let D = C;
            A.lineTo(L + I, D);
            this.last_points[J][0] = L + I;
            this.last_points[J][1] = D;
          }
        }
      }
      A.closePath();
      A.stroke();
      A.restore();
      this.current_times++;
    },

    // 转换数据
    Convert16Scale() {
      // this.arr = JSON.parse(JSON.stringify(this.getWaveChart1));
      // this.arr1 = JSON.parse(JSON.stringify(this.getWaveChart2));
      // this.arr2 = JSON.parse(JSON.stringify(this.getWaveChart3));
      // this.arr3 = JSON.parse(JSON.stringify(this.getWaveChart4));
      // this.arr4 = JSON.parse(JSON.stringify(this.getWaveChart5));
      this.adddata();
    },
    loop() {
      let _time = 62;
      let A = new Date().getTime();
      this.current_time_millis = A; // 划线前时间戳
      this.draw(
        this.y_starts,
        128,
        52,
        128,
        this.max_times,
        this.points_one_times,
        this.linectx,
        this.draw_lines_index
      );
      if (this.isstop) {
        setTimeout(this.loop, _time);
        let C = new Date().getTime(); // 划线后时间戳
        // 后 - 前时间戳 + 1 就是一定要间隔 62 毫秒, 不然就休眠补足时间
        let B = C - this.current_time_millis + 1;
        if (B < _time) {
          this.sleep(_time - B);
        }
      }
      if (this.IsEmpty()) {
        this.Convert16Scale();
      }
    },
    clearcanvans(B, F, C, D) {
      let A = parseFloat(F * ((this.gride_width * 5) / C));
      let E = this.x_start + B * A;
      // 清除 20 的地方用来重新划线
      if (B != 0) {
        D.clearRect(E, 0, 20, this.height);
      } else {
        D.clearRect(E - 10, 0, E + 20, this.height);
      }
    },
    adddata(F, C, E) {
      // if(this.arr.length == 0 && this.arr3.length == 0)return;
      for (let A = 0; A < 12; A++) {
        let G = [];
        if (!this.arr.length) {
          this.arr = JSON.parse(JSON.stringify(this.getWaveChart4));
        }
        G[0] = this.arr.shift();
        if (!this.arr1.length) {
          this.arr1 = JSON.parse(JSON.stringify(this.getWaveChart5));
        }
        G[1] = this.arr1.shift() + 80;
        this.EnQueue(G);
      }
    },
    adddata1(F, C, E) {
      if (this.arr3.length == 0) return;
      for (let A = 0; A < 120; A++) {
        let G = [];
        G[0] = this.arr3[A];
        G[1] = this.arr4[A] + 80;
        this.EnQueue(G);
      }
    },
    // 初始化参数, 宽度, 和线条颜色
    initparm() {
      this.max_times = parseInt((this.width - 0) / this.gx);
      //   this.linectx.translate(0.5,0.5);
      this.linectx.lineWidth = 2;
      this.linectx.strokeStyle = "rgba(242, 251, 76, 1)";
    },
    sleep(B) {
      let A = new Date().getTime();
      for (let C = 0; C < 10000000; C++) {
        if (new Date().getTime() - A > B) {
          break;
        }
      }
    },
    EnQueue(C) {
      if (C == null) {
        return -1;
      }
      if (this.currenData.length >= this.capacity) {
        this.currenData.remove(0);
      }
      this.currenData.push(C);
    },
    DeQueue() {
      if (this.currenData.length == 0) {
        return null;
      } else {
        return this.currenData.shift();
      }
    },
    GetSize() {
      return this.currenData.length;
    },
    IsEmpty() {
      if (this.currenData.length == 0) {
        return true;
      } else {
        return false;
      }
    },
    clear(C) {
      this.currenData = new Array();
    },
  },
  watch: {},
  computed: {
    ...mapGetters([
      "getWaveChart1",
      "getWaveChart2",
      "getWaveChart3",
      "getWaveChart4",
      "getWaveChart5",
      "waveList",
      "stepInfo",
    ]),
  },
};
</script>
<style lang="scss">
@import "../index.scss";
</style>