<template>
  <div class="practiceLogPage">
    <div class="practice-log-content rr">
      <block v-show="caseLog.length != 0">
        <div class="logItem" v-for="(item, index) in caseLog" :key="index">
          <h4>[{{ formateSeconds(item.create_at) }}]:</h4>
          <p>
            {{ item.value }}
          </p>
        </div>
      </block>
      <block v-show="caseLog.length == 0">
        <div class="logItem" >
          <h4>暂无日志数据..</h4>
        </div>
      </block>
    </div>
    <div class="controller right">
      <!-- <button class="log-btn" @click="downLog">下载完整日志</button> -->
      <button class="log-btn" @click="showLog">查看及下载完整日志</button>
    </div>
  </div>
  <a-modal
    v-model:visible="visible"
    :footer="null"
    :closable="false"
    width="630px"
  >
    <div class="logModel">
      <div class="log-model-content">
         <div class="logItem" v-for="(item, index) in caseLog" :key="index">
          [{{ formateSeconds(item.create_at) }}]:{{ item.value }}
        </div>
      </div>
      <div class="controller">
        <button class="log-btn" @click="downLog">下载完整日志</button>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { mapGetters } from "vuex";
import  {formateSeconds} from '@/utils/tool'
export default {
  data() {
    return {
      visible: false,
      formateSeconds,
    };
  },
  methods: {
    async downLog() {
      if(this.caseLog.length == 0){
        this.$message.info('暂无日志数据,请操作后下载')
        return;
      }
      const hide = this.$message.loading('下载中...', 0);
      let array = [
        [
          "日志",
        ],
      ];
      try {
        let res = await this.$http.get(this.$interface.caseLogList+'?memberCaseId='+this.memberCaseId);
        res.data.data.forEach((d) => {
          array.push([
            d
          ]);
        });
        let ws = XLSX.utils.aoa_to_sheet(array);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb,ws,'完整日志');
        XLSX.writeFile(wb,'完整日志.xlsx')
      } catch (err) {}finally{
        hide();
      }
    },
    showLog(){
      if(this.caseLog.length == 0){
        this.$message.info('暂无日志数据')
        return;
      }
      this.visible = true
    }
  },
  computed: {
    ...mapGetters(["caseLog","memberCaseId"]),
  },
};
</script>
<style lang="scss">
@import "../index.scss";
</style>