const indexData = ['0.0', '0.1', '0.2', '0.3', '0.4', '0.5', '0.6', '0.7', '0.8', '0.9', '1.0', '1.5', '2.0', '2.5', '3.0', '3.5', '4.0', '4.5', '5.0', '5.5', '6.0', '6.5', '7.0', '7.5', '8.0', '8.5', '9.0', '9.5', '10'];
const indexData1 = ['0.0', '0.5', '1.0', '1.5', '2.0', '2.5', '3.0', '3.5', '4.0', '4.5', '5.0', '5.5', '6.0', '6.5', '7.0', '8.0', '9.0', '10', '11', '12'];
//监护仪
const youchuangList = [
  {
    type: "insert_art_line",
    name: "插入动脉导管",
  },
  {
    type: "insert_cvp",
    name: "插入 CVP",
  },
  {
    type: "insert_pa_catheter",
    name: "插入 PA 导管",
  },
  {
    type: "insert_foley",
    name: "插入导尿管",
  },
];
//监护仪
const jianhuyiLise = [
  {
    type: "measure_nbp",
    name: "测量 NBP",
  },
  {
    type: "display_abp",
    name: "显示 ABP",
  },
  {
    type: "display_cvp",
    name: "显示 CVP",
  },
  {
    type: "display_pap",
    name: "显示 PAP",
  },
  {
    type: "display_pawp",
    name: "显示PAWP",
  },
  {
    type: "cardiac_output",
    name: "Cardiac Output",
  },
];
//   监护仪
const shenjingList = [
  {
    type: "train_of_four",
    name: "四个成串刺激",
  },
  {
    type: "tetanus",
    name: "强直收缩",
  },
];
//补液
const liutiList = [
  {
    type: "",
    name: "没有任何",
  },
  {
    type: "lactated_ringers",
    name: "乳酸林格",
  },
  {
    type: "normal_saline",
    name: "生理盐水",
  },
  {
    type: "albumin",
    name: "白蛋白",
  },
  {
    type: "packed_red_blood_cells",
    name: "浓缩红细胞",
  },
];
//补液
const jingmaishuzhuList = ['0', '50', '100', '150', '200', '250', '300', '350', '400', '450', '500', '600', '700', '800', '900', '1000', '2000', '3000', '4000', '5000'];
//补液
const jingmaituizhuList = ['0', '50', '100', '150', '200', '250', '300', '400', '500', '1000'];
//呼吸
const huxiList = [{
  type: 'breathing_spontaneous',
  name: '自主通气',
}, {
  type: 'bag_assist_control',
  name: '辅助/控制通气',
}, {
  type: 'breathing_ventilator',
  name: '呼吸机',
}];
const huxichaoliangList = ["0", "10", "20", "30", "40", "50", "60", "70", "80", "90", "100", "120", "140", "160", "180", "200", "220", "240", "260", "280", "300", "320", "340", "360", "380", "400", "420", "440", "460", "480", "500", "520", "540", "560", "580", "600", "620", "640", "660", "680", "700", "720", "740", "760", "780", "800", "820", "840", "860", "880", "900", "920", "940", "960", "980", "1000"];
const huxihuifulvList = ["0", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "18", "20", "22", "24", "26", "28", "30", "32"];
const huxikuishiList = ["0", "2.5", "5", "7.5", "10", "12.5", "15", "17.5", "20", "22.5", "25", "27.5","30", "32.5", "35", "37.5","40", "42.5", "45", "47.5","50"];

// 抢救
const fusuList = [
  {
    type: "call_for_help",
    name: "寻求帮助",
  },
  {
    type: "aspirate_cvp",
    name: "抽吸 CVP",
  },
  {
    type: "start_cpr",
    name: "开始心肺复苏",
  },
  {
    type: "stop_cpr",
    name: "停止心肺复苏",
  },
];

const chuchanList = ["10", "20", "30", "50", "100", "200", "300", "360"];

//   外科医生
const waikeList = [
  {
    type: "prep",
    name: "准备手术",
  },
  {
    type: "make_incision",
    name: "做切口",
  },
  {
    type: "stop_now",
    name: "立即停止",
  },
  {
    type: "resume_surgery",
    name: "恢复手术",
  },
];
const shoushuLise = [
  {
    type: "folld_field",
    name: "泛源照射野",
  },
  {
    type: "insert_chest_tube",
    name: "插入胸管",
  },
];

// 气道

const qidaoList = [
  {
    state: 'qidao_huanzhuangruangu',
    type: "place_criconid",
    name: "环状软骨加压",
    type_1: "remove_cricoid",
    name_1: "去除环状软骨",
  },

  {
    state: 'qidao_yangqimianzhao',
    type: "place_mash",
    name: "放置氧气面罩",
    type_1: "remove_mask",
    name_1: "去除氧气面罩",
  },
  {
    type: "adjust_head_position",
    name: "调整头部位置",
  },
  {
    state: 'qidao_lma',
    type: "place_lma",
    name: "放置LMA",
    type_1: "remove_lma",
    name_1: "去除LMA",
  },
  {
    type: "insert_oral_airway",
    name: "插入口腔导气管",
  },
  {
    type: "insert_nasal_airway",
    name: "插入鼻导气管",
  },
  {
    type: "suction",
    name: "抽吸导管",
  },
];
const chaguanList = [
  {
    place: "laryngoscopy_place_tube",
    remove: "laryngoscopy_remove_tube",
    name: "喉镜",
    type: "laryngoscopy",
  },
  {
    place: "fiberoptic_place_tube",
    remove: "fiberoptic_remove_tube",
    name: "光纤",
    type: "fiberoptic",
  },
  {
    place: "crico_otomy_place_tebe",
    remove: "crico_otomy_remove_tebe",
    name: "環甲膜切開",
    type: "crico",
  },
];
const shenduList = ['11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'];

export {
  indexData,
  indexData1,
  youchuangList,
  jianhuyiLise,
  shenjingList,
  liutiList,
  jingmaishuzhuList,
  jingmaituizhuList,
  huxiList,
  huxichaoliangList,
  huxihuifulvList,
  huxikuishiList,
  fusuList,
  waikeList,
  shoushuLise,
  qidaoList,
  chaguanList,
  shenduList,
  chuchanList
};