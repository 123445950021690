<template>
  <div class="controllerIndex">
    <div class="header float" style="display: flex;align-items: baseline;">
      <h4 style="width: 330px;">
        <span style="display: inline-block;"> {{
          missionType == "test"
            ? "基础练习"
            : missionType == "exercise"
            ? "练习"
            : "考试"
        }}模式：</span>
       <span style="width: 200px;display: inline-block;vertical-align: top;font-size: 15px;" class="more_line">{{caseNumber + '-' + caseName || "暂无名称" }}</span>
      </h4>
      <a-popconfirm
        title="请再次确认是否重选案例"
        ok-text="是"
        cancel-text="否"
        @confirm="toBack"
      >
        <div class="reselect right">
          <i class="bg"></i>
          重选案例
        </div>
      </a-popconfirm>
    </div>
    <div class="title" v-show="missionType != 'assess'">
      <div class="">“{{ stepInfo.caseHelp || "暂无提示" }}”</div>
    </div>
    <div class="router-info float">
      <div @click="handleLink('jieshao')">目标</div>
      <div @click="handleLink('shuqian')">术前</div>
      <div @click="handleLink('bingren')">病人</div>
    </div>
    <div class="router-content all">
      <div class="float" @click="handleLink('jianhuyi')">
        监护仪<i class="bg jhy"></i>
      </div>
      <div class="float" @click="handleLink('buye')">
        补液<i class="bg by"></i>
      </div>
      <div class="float" @click="handleLink('qidao')">
        气道<i class="bg qd"></i>
      </div>
      <div class="float" @click="handleLink('jianyan')">
        检验<i class="bg jy"></i>
      </div>
      <div class="float" @click="handleLink('huxi')">
        呼吸<i class="bg hx"></i>
      </div>
      <div class="float" @click="handleLink('qiangjiu')">
        抢救<i class="bg qj"></i>
      </div>
      <div class="float" @click="handleLink('yongyao')">
        用药<i class="bg yy"></i>
      </div>
      <div class="float" @click="handleLink('waike')">
        外科医生<i class="bg wk"></i>
      </div>
    </div>
    <div class="info float imgP">
      <img src="@/assets/images/flowmeter.jpeg" class="img1 " alt="" />
      <div class="imgD" :style="'bottom:'+imgDottom+'%'"></div>
      <div class="controller">
        <!-- <div class="controller-item">
          <a-select v-model:value="selectValue1" placeholder="请选择">
            <template #suffixIcon>
              <div class="right">
                <p class="select-tips">L/min N20</p>
                <img
                  class="select_img"
                  src="@/assets/images/ico_select.png"
                  alt=""
                />
              </div>
            </template>
            <a-select-option
              :value="item"
              v-for="(item, index) in indexData"
              :key="index"
              >{{ item }}</a-select-option
            >
          </a-select>
        </div> -->
        <div class="controller-item">
          <a-select
            v-model:value="selectValue2"
            @change="(value) => selectChange(value, 'Air')"
            placeholder="请选择"
          >
            <template #suffixIcon>
              <div class="right">
                <p class="select-tips" style="font-size: 14px !important;">L/min Air</p>
                <img
                  class="select_img"
                  src="@/assets/images/ico_select.png"
                  alt=""
                />
              </div>
            </template>
            <a-select-option
              :value="item"
              v-for="(item, index) in indexData"
              :key="index"
              style="font-size: 25px !important;"
              > <span style="font-size: 14px !important;">{{ item }}</span></a-select-option
            >
          </a-select>
        </div>
        <div class="controller-item">
          <a-select
            v-model:value="selectValue3"
            @change="(value) => selectChange(value, 'O2')"
            placeholder="请选择"
          >
            <template #suffixIcon>
              <div class="right">
                <p class="select-tips" style="font-size: 14px !important;">L/min O2</p>
                <img
                  class="select_img"
                  src="@/assets/images/ico_select.png"
                  alt=""
                />
              </div>
            </template>
            <a-select-option
              :value="item"
              v-for="(item, index) in indexData"
              :key="index"
              ><span style="font-size: 14px !important;">{{ item }}</span></a-select-option
            >
          </a-select>
        </div>
        <div
          class="controller-item"
          style="
            text-align: center;
            color: #fff;
            line-height: 32px;
            font-size: 14px;
            cursor: pointer;
            background: #3860fb;
          "
          @click="submitOk"
        >
          确认
        </div>
      </div>
      <div class="controller1">
        <img
          style="display: block;
              width: 84px;
              height: 106px;
              background-color: #EDF1FF;"
          src="@/assets/images/guan.png"
          alt=""
        />
        <div class="controller1-item">
          <a-select
            @change="(value) => selectChange(value, 'sz')"
            v-model:value="selectValue4"
            placeholder="请选择"
          >
            <template #suffixIcon>
              <img
                class="select_img"
                src="@/assets/images/ico_select.png"
                alt=""
              />
            </template>
            <a-select-option value="0.0"><span style="font-size: 14px !important;">0.0</span></a-select-option>
            <a-select-option
              :value="item"
              v-for="(item, index) in selectList"
              :key="index"
              ><span style="font-size: 14px !important;">{{ item }}</span></a-select-option
            >
          </a-select>

          <p class="select-tips" style="font-size: 14px !important;">MAC</p>
          <a-select
            @change="(value) => selectChange(value, 'yw')"
            v-model:value="selectValue5"
            placeholder="请选择"
            :disabled="
              selectValue4 == 0 || selectValue4 == undefined ? false : true
            "
          >
            <template #suffixIcon>
              <img
                class="select_img"
                src="@/assets/images/ico_select.png"
                alt=""
              />
            </template>
            <a-select-option
              :value="item.drugId"
              v-for="(item, index) in drugData"
              :key="index"
              ><span style="font-size: 14px !important;">{{ item.drugName }}</span></a-select-option
            >
          </a-select>
        </div>
      </div>
    </div>
    <div class="imgView float">
      <img v-if="stepInfo.imageA" :src="stepInfo.imageA" alt="info image" />
      <img v-if="stepInfo.imageB" :src="stepInfo.imageB" alt="info image" />
      <!-- <img :src="stepInfo.imageA || showImageInfo1" alt="info image">
         <img :src="stepInfo.imageB || showImageInfo2" alt="info image"> -->
    </div>
    <a-drawer
      class="controllerDrawer"
      placement="bottom"
      :closable="true"
      height="70%"
      v-model:visible="visible"
      @close="drawerClose"
      :get-container="false"
      :title="typeList[type]"
      :style="{ position: 'absolute' }"
    >
      <!-- <button class="controllerDrawer-close"></button> -->
      <!-- <div class="controllerDrawer-header iconcenter">
        <i class="bg" :class="type"></i>
        {{typeList[type]}}
      </div> -->
      <component :is="userComponent" />
    </a-drawer>
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import { indexData, indexData1 } from "@/utils/data";
import showImageInfo1 from "@/assets/images/shwoImageInfo1.png";
import showImageInfo2 from "@/assets/images/shwoImageInfo2.png";
export default {
  name: "practiceIndex",
  components: {},
  data() {
    return {
      showImageInfo1,
      showImageInfo2,
      indexData,
      indexData1,
      selectValue1: "0.0",
      selectValue2: "0.0",
      selectValue3: "0.0",
      selectValue4: undefined,
      selectValue5: undefined,
      visible: false,
      type: "",
      selectList: [],
      drugs: [
        { drugId: "", bolus: "" },
        { drugId: "", bolus: "" },
      ],
      select: undefined,
      typeList: {
        jieshao: "目标",
        shuqian: "术前",
        bingren: "病人",
        jianhuyi: "监护仪",
        buye: "补液",
        qidao: "气道",
        jianyan: "检验",
        huxi: "呼吸",
        qiangjiu: "抢救",
        yongyao: "用药",
        waike: "外科医生",
      },
      imgDottom:'0',
      szObj:{}
    };
  },
  mounted(){
    // this.getData()
    // localStorage.setItem("Times", JSON.stringify(0));
    // localStorage.setItem("Stops", JSON.stringify('NO'));

  },
  methods: {
    ...mapActions({
      submitStep: "exma/submitStep",
      exmaStart: "answer/exmaStart",
    }),
    async getData() {
      try {
        let res = await this.$http.post(this.$interface.examStart, {
          caseId: this.$route.query.caseId,
          missionId: this.$route.query.missionId,
        });
        localStorage.setItem("checkDataArray", JSON.stringify(res.data.data.stepInfo.checkDataArray));
        let timess=0
        let time1 =  setInterval(() => {
            if (JSON.parse(localStorage.getItem('Times')) &&JSON.parse(localStorage.getItem('Times')) !=timess ) {
              clearInterval(time1);
            }
            timess+=1
            if (timess>=res.data.data.stepInfo.checkDataArray.length || JSON.parse(localStorage.getItem('Stops'))=='Yes') {
                clearInterval(time1);
                timess-=1
            }
          console.log(timess,'timesstimess')
            localStorage.setItem("Times", JSON.stringify(timess));
        }, 1000);
      } catch (err) {}
    },
    drawerClose() {
      this.type = "";
    },
    selectChange(value, type) {
      if (type == "yw") {
        let _d = this.drugData.find((e) => e.drugId == value);
       
        if (_d.drugName == '地氟醚') {
           localStorage.setItem("MAC",'2');
        }else{
           localStorage.setItem("MAC",'1');
          
        }
        this.select = _d;
        this.selectList = _d.bolusOptions || [];
      }
      if (type == "sz") {
        if (!this.select) return;
        this.szObj = {
          memberCaseId: this.memberCaseId,
          actionTime: this.getHandleTimeAt,
          drugId: this.select.drugId,
          bolus: value,
        };
        // this.submitStep(d);
      }
      if (type == "Air") {
        // if (!this.select) return;
        this.drugs[0] = {
          drugId: "54",
          bolus: value,
        };
        this.imgDottom=Number(this.drugs[0].bolus)+Number(this.drugs[1].bolus)
        if (this.imgDottom<=10) {
         this.imgDottom= this.imgDottom*6
        }else if(this.imgDottom>10){
          this.imgDottom= (this.imgDottom-10)*4+60
        }
      }
      if (type == "O2") {
        this.drugs[1] = {
          drugId: "55",
          bolus: value,
        };
        this.imgDottom=Number(this.drugs[0].bolus)+Number(this.drugs[1].bolus)
         if (this.imgDottom<=10) {
          this.imgDottom= this.imgDottom*6
        }else if(this.imgDottom>10){
          this.imgDottom= (this.imgDottom-10)*3.4+60
        }
        console.log(this.imgDottom,'this.imgDottom');
      }
    },
    submitOk() {
      let drugsList = [];
      this.drugs.forEach((item, index) => {
        if (item.bolus != "" && item.bolus != "0.0") {
          drugsList.push(item);
        }
      });
      let d = {
        memberCaseId: this.memberCaseId,
        actionTime: this.getHandleTimeAt,
        component: "drug",
        otherData: { drugs: drugsList },
        ...this.szObj
      };
      this.submitStep(d);
    },
    handleLink(type) {
      this.type = type || "";
      this.$nextTick(() => {
        this.visible = true;
      });
    },
    toBack() {
      this.$router.replace("/");
    },
  },
  computed: {
    ...mapGetters([
      "stepInfo",
      "caseName",
      "caseNumber",
      "missionType",
      "drugData",
      "getHandleTimeAt",
      "memberCaseId",
    ]),
    userComponent() {
      let { type } = this;
      let componentList = {
        jieshao: defineAsyncComponent((_) => import("./modules/jieshao")),
        shuqian: defineAsyncComponent((_) => import("./modules/shuqian")),
        bingren: defineAsyncComponent((_) => import("./modules/bingren")),
        jianhuyi: defineAsyncComponent((_) => import("./modules/jianhuyi")),
        buye: defineAsyncComponent((_) => import("./modules/buye")),
        qidao: defineAsyncComponent((_) => import("./modules/qidao")),
        jianyan: defineAsyncComponent((_) => import("./modules/jianyan")),
        huxi: defineAsyncComponent((_) => import("./modules/huxi")),
        qiangjiu: defineAsyncComponent((_) => import("./modules/qiangjiu")),
        yongyao: defineAsyncComponent((_) => import("./modules/yongyao")),
        waike: defineAsyncComponent((_) => import("./modules/waike")),
      };
      if (type) return componentList[type];
      return false;
    },
  },
};
</script>
<style lang="scss">
@import "index.scss";
</style>
<style scoped>
.imgP{
  position: relative;
}
.imgD{
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: black;
  left: 5%;
  bottom: 0%;
}
.flex_start
{
display:flex;
align-items: center;
justify-content: flex-start;
}
</style>