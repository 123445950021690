<template>
  <div class="practiceContentPage float">
    <div class="practiceContentLeft">
      <div class="practiceContentChart practiceContentChart_top">
        <chart :type="true" />
      </div>
      <div class="practiceContentChart practiceContentChart_bottom">
        <chart-bottom :type="false" />
      </div>
      <div class="practiceContentLog">
        <log />
      </div>
    </div>
    <div class="practiceContentRight">
      <info />
    </div>
  </div>
</template>
<script>
import practiceChart from "./chart.vue";
import practiceChartBttom from "./chart1.vue";
import practiceLog from "./log.vue";
import practiceInfo from "./info.vue";
export default {
  components: {
    chart: practiceChart,
    chartBottom: practiceChartBttom,
    log: practiceLog,
    info: practiceInfo,
  },
  data() {
    return {
      isPlay: false,
    };
  },
};
</script>
<style lang="scss">
@import "../index.scss";
</style>
