import setting from '../config/setting';
import global from '../config/global';
export default {
    data() {
        return {
            heartTime:null,
        };
    },
    created() {
        
        this.startSock();
        console.log('mixins created',setting.takeToken());
    },
    mounted(){
        console.log(global)
    },
    unmounted() {
        console.log('mixins unmounted');
        this.closeSock();
    },
    methods: {
        heartTimeOut() {
            clearTimeout(this.heartTime)
            console.log('cleartimeout', this.heartTime)
            this.heartTime = setTimeout(() => {
                this.heartbeat();
            }, 10000);
        },
        startSock() {
            window.ws = new WebSocket(
                `ws://anesthesia-api.jeemoo.net/rest-api/login/ws/${setting.takeToken()}`
            );
            window.ws.onopen = () => {
                this.heartTimeOut();
            };
            window.ws.onmessage = msg => {
                console.log(msg,'onmessage');
            };
            window.ws.onclose = () => {
                clearTimeout(this.heartTime)
                console.log("连接关闭", this.heartTime);
            };
        },
        heartbeat() {
            this.heartTimeOut();
            window.ws.send('PING');
        },
        closeSock() {
            window.ws.close();
           
        }
    }

}