<template>
  <div class="practiceInfoPage rr" style="font-size: 14px;">
    <div class="view1 view_page1 green_page2">
      <div class="header float">
        <h4 class="title green_page" style="font-size: 14px;">ECG</h4>
      </div>
      <div class="template1 float">
        <div>
          <div class="left mb20">
            <div class="text1 green_page tleft" style="font-size: 14px;">II</div>
            <div class="text1 green_page tleft" style="font-size: 14px;">0.2</div>
          </div>
          <div class="left">
            <div class="text1 green_page tleft" style="font-size: 14px;">V5</div>
            <div class="text1 green_page tleft" style="font-size: 14px;">0.3</div>
          </div>
        </div>
        <div class="text2">{{  parseFloat(stepInfoVitalSigns.ecg).toFixed(0)}}</div>
      </div>
      <div class="tag_ecg right" @click="playSound">
        <p class="tag" style="cursor: pointer;">{{Sound==true?'Sound:Off':'Sound:On'}}</p>
      </div>
    </div>
    <!-- NPB -->
    <div
      class="view1 green_page"
      v-show="
        jianhuyiDisplay != 'display_abp' &&
        jianhuyiDisplay != 'display_pap' &&
        jianhuyiDisplay != 'display_cvp' &&
        jianhuyiDisplay != 'display_pawp' &&
        jianhuyiDisplay != 'cardiac_output'
      "
    >
      <div class="header float">
        <h4 class="title green_page" style="font-size: 14px;">NBP</h4>
        <div class="text1 tright" style="font-size: 14px;width: 60px;">
          {{parseFloat(stepInfoVitalSigns.nbp_h).toFixed(0)  }}&nbsp;/&nbsp;{{      
            parseFloat(stepInfoVitalSigns.nbp_l).toFixed(0)
          }}
        </div>
      </div>
      <div class="template2 float m15">
        <p class="tag1" @click="nbpRun">GO</p>
        <div class="right">
          <div
            class="text1 tleft"
            v-show="!nbpStart"
            style="width: auto; margin-right: 20px;font-size: 14px;"
          >
            {{
              nbpAt >= 120
                ? "3min"
                : nbpAt > 60 && nbpAt < 120
                ? "2min"
                : `${nbpAt}s`
            }}
          </div>
          <div class="text1 tright" style="width: auto;font-size: 14px;">
            {{ nbpStart ? `cuff：${parseFloat(nbpValue[0]).toFixed(0)}` : parseFloat(stepInfoVitalSigns.nbp).toFixed(0) }}
          </div>
        </div>
      </div>
    </div>
    <!-- ABP -->
    <div class="view1 blue_page" v-show="jianhuyiDisplay == 'display_abp'">
      <div class="header float">
        <h4 class="title blue_page" style="font-size: 14px;">ABP</h4>
      </div>
      <div class="template2 float m15">
        <div class="text1 tleft" style="font-size: 16px;">{{ parseFloat(stepInfoVitalSigns.abp).toFixed(0) || "暂无" }}</div>
      </div>
    </div>
    <!-- CVP -->
    <div class="view1 green_page" v-show="jianhuyiDisplay == 'display_cvp'">
      <div class="header float">
        <h4 class="title green_page" style="font-size: 14px;">CVP</h4>
      </div>
      <div class="template2 float m15">
        <div class="text1 tleft" style="font-size: 16px;">{{ parseFloat(stepInfoVitalSigns.cvp).toFixed(0) || "暂无" }}</div>
      </div>
    </div>
    <!-- cardiac_output -->
    <div class="view1 blue_page" v-show="jianhuyiDisplay == 'cardiac_output'">
      <div class="header float">
        <h4 class="title blue_page" style="font-size: 14px;">Cardiac Output</h4>
      </div>
      <div class="template2 float m15">
        <div class="text1 tleft" style="font-size: 16px;">
          {{ stepInfoVitalSigns.cardiac_output || "暂无" }}
        </div>
      </div>
    </div>
    <!-- PAP -->
    <div class="view1 blue_page" v-show="jianhuyiDisplay == 'display_pap'">
      <div class="header float">
        <h4 class="title blue_page" style="font-size: 14px;">PAP</h4>
        <div class="text1 tright" style="font-size: 16px;">
          {{ parseFloat(stepInfoVitalSigns.pap_h).toFixed(0) || "0" }}&nbsp;/&nbsp;{{
            parseFloat(stepInfoVitalSigns.pap_l).toFixed(0) || "0"
          }}
        </div>
      </div>
      <div class="template2 float m15">
        <div class="text1 tright" style="width: 100%;font-size: 16px;">
          {{ parseFloat(stepInfoVitalSigns.pap_m).toFixed(0) || "0" }}
        </div>
      </div>
    </div>
    <!-- PAWP -->
    <div class="view1 blue_page" v-show="jianhuyiDisplay == 'display_pawp'">
      <div class="header float">
        <h4 class="title blue_page" style="font-size: 14px;">PAWP</h4>
      </div>
      <div class="template2 float m15">
        <div class="text1 tleft" style="font-size: 16px;">{{ parseFloat(stepInfoVitalSigns.pawp).toFixed(0) || "暂无" }}</div>
      </div>
    </div>
    <div class="view1 blue_page">
      <div class="header float">
        <h4 class="title blue_page" style="font-size: 14px;">SpO2</h4>
      </div>
      <div class="template1 float">
        <div>
          <!-- <div class="text1 tleft">无sec</div> -->
          <div class="text1 tleft" style="width:80px;font-size:18px">Rate {{  parseFloat(stepInfoVitalSigns.ecg).toFixed(0)}}</div>
          <!-- <div class="text1 tleft">无</div> -->
        </div>
        <div class="text3">{{ parseFloat(stepInfoVitalSigns.spo2).toFixed(0) }}</div>
      </div>
    </div>
    <div class="view1 yellow_page">
      <div class="header float">
        <h4 class="title yellow_page" style="font-size: 14px;">AGM</h4>
      </div>
      <div class="template1 float m10">
        <div class="left">
          <div>
            <div class="text1 tleft"></div>
            <div class="text1 tleft" style="font-size: 14px;">I</div>
            <div class="text1 tleft" style="font-size: 14px;">E</div>
          </div>
          <div>
            <div class="text1 tleft" style="font-size: 14px;">O2</div>
            <div class="text1 tleft" style="font-size: 14px;">{{ parseFloat(stepInfoVitalSigns.agm_o2i).toFixed(0) }}</div>
            <div class="text1 tleft" style="font-size: 14px;">{{ stepInfoVitalSigns.agm_o2g?parseFloat(stepInfoVitalSigns.agm_o2g).toFixed(0) :stepInfoVitalSigns.agm_o2g}}</div>
          </div>
          <div class="ml10">
            <div class="text1 tcenter" style="font-size: 14px;">CO2</div>
            <div class="text4" style="text-align: center;">{{parseFloat( stepInfoVitalSigns.agm_co2).toFixed(0) }}</div>
          </div>
        </div>
        <div>
          <div class="text1 tright" style="font-size: 14px;">{{isf}}</div>
          <div class="text1 tright" style="font-size: 14px;">{{ parseFloat(stepInfoVitalSigns.agm_svfi).toFixed(1) }}</div>
          <div class="text1 tright" style="font-size: 14px;">{{ parseFloat(stepInfoVitalSigns.agm_svfe).toFixed(1) }}</div>
        </div>
      </div>
    </div>
    <div class="view1 yellow_page">
      <div class="header float">
        <h4 class="title yellow_page" style="font-size: 14px;">Spirometer</h4>
      </div>
      <div class="template1 float m10">
        <div class="left">
          <div class="text1 tleft" style="font-size: 14px;">TV</div>
          <div class="text1 tleft" style="font-size: 14px;">{{ parseFloat(stepInfoVitalSigns.tv).toFixed(0) }}</div>
        </div>
        <div class="right">
          <div class="text1 tright" style="font-size: 14px;">RR</div>
          <div class="text1 tright" style="font-size: 14px;">{{ parseFloat(stepInfoVitalSigns.rr).toFixed(0) }}</div>
        </div>
      </div>
    </div>
    <div class="view1">
      <div class="header float">
        <h4 class="title" style="font-size: 14px;">Airway Pressure</h4>
      </div>
      <div class="template1 float m10">
        <div class="left">
          <div class="text1 tleft" style="font-size: 14px;">PIP</div>
          <div class="text1 tleft" style="font-size: 14px;">{{ parseFloat(stepInfoVitalSigns.pip).toFixed(0) }}</div>
        </div>
        <div class="right">
          <div class="text1 tright" style="font-size: 14px;">PEEP</div>
          <div class="text1 tright" style="font-size: 14px;">{{ parseFloat(stepInfoVitalSigns.peep).toFixed(0) }}</div>
        </div>
      </div>
    </div>
    <div class="view1 n_border blue_page">
      <div class="header">
        <h4 class="title" style="font-size: 14px;">Temperature</h4>
        <div class="text5">{{ parseFloat(stepInfoVitalSigns.temper).toFixed(1) }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      Sound:false,
      isf:'ISF',
    };
  },
  created() {
    this.nbpTimeStart();
  },
  mounted(){
    this.isf='ISF'
    localStorage.removeItem('MAC');
    this.Sound=false

    const setItem = localStorage.setItem
    localStorage.setItem = function (name, value){
    setItem.apply(this, arguments)
    var event = new Event('setItem')
    event.key = name
    event.value = value
    window.dispatchEvent(event);
    }
    let that =this
    window.addEventListener('setItem', function (e) { 
       if (Number(e.value) ==2) {
        that.isf='DSF'
      }else{
        that.isf='ISF'
      }
    })
  },
  methods: {
    ...mapActions({
      nbpRun: "controller/nbpRun",
      nbpTimeStart: "controller/nbpTimeStart",
    }),
    playSound() {
      this.$parent.isPlay = !this.$parent.isPlay;
      this.Sound=!this.Sound
    }
  },
  computed: {
    ...mapGetters([
      "stepInfoVitalSigns",
      "jianhuyiDisplay",
      "nbpValue",
      "nbpStart",
      "nbpAt",
    ]),
  },
};
</script>
<style lang="scss">
@import "../index.scss";
</style>
